@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap";
:root {
  --color-blue-primary: 51, 66, 206;
  --color-blue-darker: 46, 59, 185;
  --color-light: 233, 236, 239;
  --color-white: 248, 249, 250;
  --color-dark: 33, 37, 41;
  --color-black: 0, 0, 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  color: rgb(var(--color-dark));
  font-family: Poppins, sans-serif;
}

::selection {
  color: rgb(var(--color-white));
  background-color: rgb(var(--color-blue-primary));
}

body, header {
  background-color: rgb(var(--color-white));
}

.btn, .navbar-links li a, a, a:active {
  text-decoration: none;
}

#contact ul, .navbar-links li {
  list-style: none;
}

#about, footer {
  color: rgb(var(--color-white));
}

li, p, span {
  font-size: 1.6rem;
  font-weight: 400;
}

a {
  color: rgb(var(--color-dark));
  font-weight: 500;
  transition: all .3s;
}

.navbar-links li a:hover, a:hover {
  color: rgb(var(--color-blue-primary));
}

a.active {
  color: rgb(var(--color-blue-primary)) !important;
}

img {
  object-fit: cover;
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
}

.btn {
  background-color: rgb(var(--color-blue-primary));
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 1rem 5rem;
  font-size: 1.6rem;
  transition: all .1s;
  display: flex;
  color: rgb(var(--color-white)) !important;
}

.btn:hover {
  background-color: rgb(var(--color-blue-darker));
  transform: scale(1.05);
}

.btn-secondary {
  border: 3px solid rgb(var(--color-blue-primary));
  background: none;
  color: rgb(var(--color-blue-primary)) !important;
}

#contact, .project {
  background-color: rgb(var(--color-light));
}

.btn-secondary:hover {
  background-color: rgb(var(--color-blue-primary));
  color: rgb(var(--color-white)) !important;
}

#about, .toggle-button .bar, footer {
  background-color: rgb(var(--color-dark));
}

.hidden {
  display: none !important;
}

.loading-container {
  z-index: 2;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  overflow-x: hidden;
}

.loading {
  border: 8px solid rgba(var(--color-blue-darker), .45);
  border-top-color: rgb(var(--color-blue-primary));
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: 1s ease-in-out infinite spin;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.percContentToScroll, .percOfScrolled {
  height: 5px;
  position: fixed;
  top: 0;
}

.percContentToScroll {
  background-color: rgba(var(--color-blue-darker), .3);
  z-index: 2;
  width: 100%;
}

.percOfScrolled {
  background-color: rgb(var(--color-blue-primary));
  z-index: 3;
  width: 0%;
}

header {
  box-shadow: 1px 1px 0px rgba(var(--color-black), .1);
  z-index: 1;
  width: 100%;
  padding: 1.5rem 15rem;
  position: fixed;
  top: 0;
}

.navbar {
  color: rgb(var(--color-dark));
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.logo {
  transition: all .3s;
  font-size: 3.2rem !important;
  font-weight: 700 !important;
}

.logo:hover {
  transform: rotate(2deg);
}

.navbar-links ul {
  align-items: center;
  gap: 2rem;
  display: flex;
}

.navbar-links li a {
  color: rgb(var(--color-dark));
}

.navbar-links .btn {
  padding: 1rem 2rem;
}

.toggle-button {
  flex-direction: column;
  justify-content: space-between;
  width: 3rem;
  height: 2.1rem;
  display: none;
  position: absolute;
  top: 1.2rem;
  right: 1rem;
}

#home, #projects {
  flex-direction: column;
  display: flex;
}

.toggle-button .bar {
  border-radius: 10px;
  width: 100%;
  height: 3px;
}

#home {
  background: url("wave.ae4f6efd.svg") bottom / cover no-repeat;
}

.hero {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  animation-name: fadeIn;
  animation-duration: 1s;
  display: flex;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.hero-title {
  text-align: center;
  color: #0000;
  z-index: 0;
  background: url("https://i.gifer.com/origin/0c/0c22fcec9723a9839ae87b7b77c2ca85_w200.gif") top / cover no-repeat text;
  max-width: 65vw;
  padding: 1rem 0;
  font-size: 6.4rem;
  line-height: 1.1;
  position: relative;
}

.hero-content {
  text-align: center;
  margin-top: 2rem;
  font-weight: 400;
}

.hero-buttons {
  gap: 1.5rem;
  margin-top: 3rem;
  display: flex;
}

#about {
  grid-template-columns: 30rem 1fr;
  justify-content: center;
  gap: 7rem;
  padding: 7rem 30rem 10rem;
  display: grid;
}

.section-title {
  font-size: 4.8rem;
}

.about-img {
  justify-content: center;
  align-items: center;
  display: flex;
}

.about-img img {
  width: 100%;
  height: 100%;
}

.about-texts {
  word-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: hidden;
}

#skills {
  background: url("blob.0f7b2e7e.svg") top / cover no-repeat;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 7rem 20rem 0;
  display: flex;
}

.skills {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  max-width: 90rem;
  display: flex;
}

.skill-badge {
  border: 3px solid rgb(var(--color-blue-primary));
  color: rgb(var(--color-blue-primary));
  cursor: default;
  background-color: #0000;
  border-radius: 10px;
  margin-bottom: .3rem;
  padding: .7rem;
  transition: all .3s;
}

.skills .skill-badge {
  opacity: 0;
}

.skill-badge:hover {
  transform: rotate(10deg);
}

.appear {
  animation-name: appear;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#projects {
  align-items: center;
  gap: 3rem;
  padding: 7rem 20rem;
  display: flex;
}

.project-cards {
  grid-template-columns: 1fr;
  gap: 2rem;
  display: grid;
}

#contact ul, .project-buttons {
  gap: 1rem;
  display: flex;
}

.project {
  box-shadow: 2px 2px 1px rgba(var(--color-black), .1);
  border-radius: 5px;
  column-gap: 3rem;
  width: 75vw;
  padding: 3rem;
  transition: all .3s;
  display: grid;
}

.project:nth-of-type(odd) {
  grid-template-columns: 40rem 1fr;
}

.project:nth-of-type(2n) {
  grid-template-columns: 1fr 40rem;
}

.project:nth-of-type(2n) .project-buttons {
  justify-content: flex-start;
}

.project:hover {
  transform: translateY(-5px);
}

.project-img {
  justify-content: center;
  align-items: center;
  display: flex;
}

.project img {
  height: 250px;
}

.project-details {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.project-title {
  font-size: 3.2rem;
}

.project-skills {
  flex-wrap: wrap;
  gap: .5rem;
  display: flex;
}

.project-buttons {
  justify-content: end;
  margin-top: 1rem;
}

#contact {
  grid-template-columns: 1fr 30rem;
  gap: 10rem;
  padding: 5rem 20rem;
  display: grid;
}

#contact ul {
  flex-direction: column;
  align-items: flex-start;
}

#contact a {
  color: rgb(var(--color-blue-darker));
  justify-content: center;
  display: flex;
}

.list-icon {
  fill: rgb(var(--color-blue-darker));
  width: 2.4rem;
  margin-right: .5rem;
}

#contact a:hover, .list-icon:hover {
  fill: #2935a5;
  color: #2935a5;
}

footer {
  text-align: center;
  padding: 2rem;
}

@media only screen and (width <= 1300px) {
  header {
    padding: 1rem 5rem;
  }

  .hero {
    padding: 10rem 2rem;
  }

  .hero-title {
    margin-bottom: 3rem;
    line-height: 1.2;
  }

  #about {
    gap: 3rem;
    padding: 5rem 3rem;
  }

  #projects {
    padding: 5rem 2rem;
  }

  .project:nth-of-type(odd) {
    flex-direction: column;
    display: flex;
  }

  .project:nth-of-type(2n) {
    flex-direction: column-reverse;
    display: flex;
  }

  .project img {
    margin-bottom: 2rem;
  }

  .project-buttons {
    text-align: center;
    flex-direction: column;
  }

  #contact {
    grid-template-columns: repeat(2, auto);
    gap: 2rem;
    padding: 3rem 10rem;
  }
}

@media only screen and (width <= 768px) {
  .navbar-links ul li, .project-buttons {
    text-align: center;
  }

  #about, #contact, .project-cards {
    grid-template-columns: 1fr;
  }

  header {
    background-color: rgb(var(--color-dark));
    border-top: 0;
    padding: 1rem 2rem;
  }

  header a {
    color: rgb(var(--color-white));
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links.active, .toggle-button {
    display: flex;
  }

  .toggle-button .bar {
    background-color: rgb(var(--color-white));
  }

  .navbar-links {
    width: 100%;
    margin-top: 1rem;
    display: none;
  }

  .navbar-links ul {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .navbar-links ul li a {
    color: rgb(var(--color-white));
    padding: .5rem 1rem;
  }

  a.active {
    color: rgb(var(--color-white)) !important;
  }

  .hero {
    min-height: auto;
    padding: 10rem 2rem;
  }

  .hero-title {
    max-width: 100%;
    margin-bottom: 3rem;
    font-size: 4.8rem;
    line-height: 1.2;
  }

  .hero-content {
    margin-top: 0;
  }

  .hero-buttons {
    flex-direction: column;
    display: flex;
  }

  #about {
    gap: 3rem;
    padding: 5rem 3rem;
  }

  #skills {
    background: none;
    padding: 3rem;
  }

  #projects {
    background: none;
    padding: 5rem 1rem;
  }

  .project {
    flex-direction: column;
    display: flex;
  }

  .project img {
    display: none;
  }

  .project-buttons {
    flex-direction: column;
  }

  #contact {
    gap: 2rem;
    padding: 3rem;
  }
}

@media only screen and (width <= 1300px) {
  .project {
    flex-direction: column;
    display: flex;
  }

  .project img {
    order: 0;
  }
}
/*# sourceMappingURL=index.c768d1f0.css.map */
